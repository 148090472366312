var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inbox-leftbar"},[_c('b-button',{attrs:{"block":"","variant":"danger"},on:{"click":function($event){_vm.showModal = true}}},[_vm._v("Compose")]),_c('div',{staticClass:"mail-list mt-4"},[_c('router-link',{staticClass:"text-danger",attrs:{"tag":"a","to":"/email/inbox"}},[_c('i',{staticClass:"ri-inbox-fill align-bottom mr-2"}),_vm._v("Inbox "),_c('span',{staticClass:"badge badge-soft-danger float-right ml-2"},[_vm._v("7")])]),_c('router-link',{attrs:{"tag":"a","to":"/email/inbox"}},[_c('i',{staticClass:"ri-star-line align-bottom mr-2"}),_vm._v("Starred ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)],1),_c('h6',{staticClass:"mt-4"},[_vm._v("Labels")]),_vm._m(6),_c('b-modal',{attrs:{"title":"New Message","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){_vm.showModal = false}}},[_vm._v("Close")]),_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Send "),_c('i',{staticClass:"fab fa-telegram-plane ml-1"})])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('form',[_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"email","placeholder":"To"}})]),_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Subject"}})]),_c('div',{staticClass:"form-group"},[_c('ckeditor',{attrs:{"editor":_vm.editor},model:{value:(_vm.editorData),callback:function ($$v) {_vm.editorData=$$v},expression:"editorData"}})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript: void(0);"}},[_c('i',{staticClass:"ri-time-line align-bottom mr-2"}),_vm._v("Snoozed")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript: void(0);"}},[_c('i',{staticClass:"ri-file-list-2-line align-bottom mr-2"}),_vm._v("Draft"),_c('span',{staticClass:"badge badge-soft-info float-right ml-2"},[_vm._v("32")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript: void(0);"}},[_c('i',{staticClass:"ri-send-plane-2-line align-bottom mr-2"}),_vm._v("Sent Mail")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript: void(0);"}},[_c('i',{staticClass:"ri-delete-bin-line align-bottom mr-2"}),_vm._v("Trash")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript: void(0);"}},[_c('i',{staticClass:"ri-price-tag-3-line align-bottom mr-2"}),_vm._v("Important")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript: void(0);"}},[_c('i',{staticClass:"ri-error-warning-line align-bottom mr-2"}),_vm._v("Spam")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-group b-0 mail-list"},[_c('a',{staticClass:"list-group-item border-0",attrs:{"href":"#"}},[_c('span',{staticClass:"mdi mdi-circle text-info mr-2"}),_vm._v("Web App")]),_c('a',{staticClass:"list-group-item border-0",attrs:{"href":"#"}},[_c('span',{staticClass:"mdi mdi-circle text-warning mr-2"}),_vm._v("Recharge")]),_c('a',{staticClass:"list-group-item border-0",attrs:{"href":"#"}},[_c('span',{staticClass:"mdi mdi-circle text-dark mr-2"}),_vm._v("Wallet Balance")]),_c('a',{staticClass:"list-group-item border-0",attrs:{"href":"#"}},[_c('span',{staticClass:"mdi mdi-circle text-primary mr-2"}),_vm._v("Friends")]),_c('a',{staticClass:"list-group-item border-0",attrs:{"href":"#"}},[_c('span',{staticClass:"mdi mdi-circle text-success mr-2"}),_vm._v("Family")])])
}]

export { render, staticRenderFns }